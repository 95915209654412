<template>
  <div>
    <carousel></carousel>
    <aboutme class="mt-3"></aboutme>
  </div>
</template>

<script>
import carousel from "@/components/carousel.vue";
import aboutme from "@/components/about.vue";

export default {
  name: "home",
  components: {
    carousel,
    aboutme,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

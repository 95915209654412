<template>
  <v-carousel cycle interval="5000" height="400">
    <v-carousel-item
      v-for="(slide, index) in slides"
      :key="index"
      :src="slide.img"
      :alt="slide.title"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "caroussel",
  data: () => ({
    slides: [
      { title: "Chantier Heron", img: require("../assets/DSC06537.webp") },
      {
        title: "Chantier Heron 1",
        img: require("../assets/Panorama_Heron_1.webp"),
      },
      {
        title: "Chantier Heron 2",
        img: require("../assets/Panorama_Heron_2.webp"),
      },
      {
        title: "Rouxel Matériaux",
        img: require("../assets/DSC09106_redimensionner.webp"),
      },
    ],
  }),
};
</script>

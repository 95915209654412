<template>
  <v-sheet color="grey lighten-5" class="pa-5">
    <h3 class="text-center text-h5 font-weight-medium grey--text text--darken-3">
      Particuliers et professionnels
    </h3>
    <h3 class="text-center text-h5 font-weight-medium grey--text text--darken-3">
      Détail ou gros volume
    </h3>
    <h3 class="text-center text-h5 font-weight-medium grey--text text--darken-3">
      Sur place ou livré
    </h3>
    <h3 class="text-center text-h6 font-weight-medium grey--text text--darken-3 mt-3">
      ROUXEL Matériaux à Vannes - Morbihan vous propose tous les matériaux pour
      l'aménagement de votre jardin
    </h3>
    <v-row dense>
      <v-col v-for="(card, index) in cards" :key="index" cols="6">
        <v-card class="mt-5 mx-3">
          <v-img :src="card.img"></v-img>
          <v-card-title v-text="card.title"></v-card-title>
          <v-card-text class="text-body-2" v-text="card.text"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue" text :to="card.href">Voir plus...</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "about",
  data: () => ({
    cards: [
      {
        title: "Matériaux de décoration",
        text:
          "Graviers de couleur : Blanc, gris, beige, bordeaux ,rouge, rose, noir, ocre, ...",
        img: require("../assets/Materiaux-de-decoration.webp"),
        href: 'decoration'
      },
      {
        title: "Paillage",
        text: "Galets, pouzzolane, ardoise, ...",
        img: require("../assets/Paillage.webp"),
        href: 'paillage'
      },
      {
        title: "Matériaux à maçonner",
        text: "Sable de Loire, sable à enduire, sable à maçonner, mélange à béton, gravier, ...",
        img: require("../assets/materiauxamaconner.webp"),
        href: 'maconner'
      },
      {
        title: "Matériaux techniques",
        text: "Sable filtrant, sable de réseau, sable équestre, sable d'aire de jeux, ...",
        img: require("../assets/materiaux_techniques.webp"),
        href: 'techniques'
      },
    ],
  }),
};
</script>
